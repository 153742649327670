import React, { useCallback, useEffect, useState } from 'react';
import { NavigationContext } from './contexts';
import DesktopArticles from './DesktopArticles';
import DesktopMenu from './DesktopMenu';
import { NavBarLink } from './DesktopNavBar.style';
import DesktopNavLinks from './DesktopNavLinks';

import type { NavigationProps, Trending } from '../types';
import { trackUserClicksTopLevelItem } from '../analytics';

function DesktopNavBar(props: NavigationProps) {
  const { navigation = {}, orderedMainNavigation = [] } = props;

  const [activeMenu, setActiveMenu] = useState('');
  const [trending, setTrending] = useState<Trending>({
    articles: [],
    label: '',
    slug: '',
  });

  const clearMenu = useCallback(() => {
    setActiveMenu('');
  }, [setActiveMenu]);

  useEffect(() => {
    document.addEventListener('click', clearMenu);

    return () => {
      document.removeEventListener('click', clearMenu);
    };
  }, [clearMenu]);

  return (
    <NavigationContext.Provider
      value={{
        activeMenu,
        setActiveMenu,
        setTrending,
        trending,
      }}
    >
      {/**
       * The first-level nav links are render side-by-side in the navbar.
       */}
      <ul data-testid="desktop-nav-bar-main-links">
        {orderedMainNavigation.map((key) => {
          const firstLevel = navigation[key];

          if (!firstLevel) {
            return null;
          }

          if (!firstLevel?.children?.length) {
            return (
              <li key={key}>
                <NavBarLink
                  $underlineOnHover={!!firstLevel.slug}
                  ariaLabel={firstLevel.label}
                  label={firstLevel.label}
                  onClick={() =>
                    trackUserClicksTopLevelItem(
                      firstLevel.label,
                      firstLevel.slug,
                    )
                  }
                  onMouseEnter={clearMenu}
                  to={firstLevel.slug}
                />
              </li>
            );
          }

          return (
            <li key={key}>
              {/**
               * The second-level nav links are render top-to-bottom in a dropdown that opens
               * to the bottom.
               */}
              <DesktopMenu
                articles={firstLevel.trendingArticles}
                id={firstLevel._id}
                label={firstLevel.label}
                slug={firstLevel.slug}
              >
                <DesktopNavLinks
                  items={firstLevel.children}
                  navItem={firstLevel}
                  navigation={navigation}
                />
                <DesktopArticles
                  renderPlaceholderColumn={
                    !firstLevel.label.toLowerCase().includes('find care')
                  }
                />
              </DesktopMenu>
            </li>
          );
        })}
      </ul>
    </NavigationContext.Provider>
  );
}

export default DesktopNavBar;
