import memoize from 'lodash/memoize';

import get from 'lodash/get';

import sanitizeImage from 'state/sanitizers/sanitizeImage';

import { ArticleImage } from 'types';

export default memoize((articleImage: unknown): ArticleImage | null => {
  const id = get(articleImage, '_key');

  if (!id) {
    return null;
  }

  return {
    id,
    type: get(articleImage, '_type', 'articleImage'),
    isFullWidth: get(articleImage, 'displayFullWidth', false),
    caption: get(articleImage, 'caption', ''),
    desktopImage: sanitizeImage(
      get(articleImage, 'desktopImage.asset'),
      get(articleImage, 'desktopImage.alt', '')
    ),
    mobileImage: sanitizeImage(
      get(articleImage, 'mobileImage.asset'),
      get(articleImage, 'mobileImage.alt', '')
    ),
  };
});
