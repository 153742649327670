import { useCallback, useRef, useState } from 'react';
import useMutationObserver from 'hooks/useMutationObserver';

export enum AD_TYPES {
  BOTTOM_RAIL = 'bottom_rail',
  CORNER_VIDEO = 'corner_video',
}

const AD_TYPE_SELECTORS = {
  [AD_TYPES.BOTTOM_RAIL]: '#pw-oop-bottom_rail',
  [AD_TYPES.CORNER_VIDEO]: '#corner_ad_video',
};

function getPlacements() {
  if (typeof document === 'undefined') {
    return {};
  }

  const placements = (
    Object.entries(AD_TYPE_SELECTORS) as Array<[AD_TYPES, string]>
  ).reduce(
    (acc, [key, value]) => {
      acc[key] = document.querySelector(value);

      return acc;
    },
    {} as Record<AD_TYPES, HTMLElement | null>,
  );

  return placements;
}

function useAdPlacements(): Partial<Record<AD_TYPES, HTMLElement | null>> {
  const target = useRef(
    typeof document !== 'undefined' ? document?.body : null,
  );
  const [pageAds, setPageAds] = useState(getPlacements());

  const callback = useCallback(() => {
    setPageAds(getPlacements());
  }, []);

  useMutationObserver(target, callback, {
    attributes: false,
    characterData: false,
    childList: true,
    subtree: true,
  });

  return pageAds;
}

export default useAdPlacements;
