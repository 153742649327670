import React, { FC } from 'react';

import BlockContent from '@sanity/block-content-to-react';
import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';

import { Img } from 'styled';
import {
  Container,
  ImageContainer,
  TextContainer,
  InfoSectionContainer,
  SupportingSectionContainer,
} from './style';

import { CalloutColorScheme } from 'constants/ColorScheme';
import {
  ListItem,
  ExternalLink,
  InternalLink,
  BlockRenderer,
  SansSerif,
} from 'constants/PortableTextSerializer';

import { ArticleCallout as IArticleCallout } from 'types';
import DynamicFeatherIcon from 'components/DynamicFeatherIcon';

interface Props {
  articleCallout: IArticleCallout | null;
}

const ArticleCallout: FC<Props> = ({ articleCallout }) => {
  if (!articleCallout) {
    return null;
  }

  const { color, infoSectionText, supportingSectionText, image } =
    articleCallout;
  const colorScheme = CalloutColorScheme[color];

  return (
    <Container
      className="disable-ads"
      style={color === 'WHITE' ? { outline: '1px solid #A5A5A5' } : undefined}
    >
      {!!image.id && (
        <ImageContainer>
          <Img
            alt={image.alt}
            sizes="(max-width: 768px) 1470px, (max-width: 1024px) 700px"
            src={sanityImgUtil(image, 1470)}
            srcSet={sanityImgSrcSetUtil(image, 1470, 700, 700)}
          />
        </ImageContainer>
      )}
      <TextContainer>
        {!!infoSectionText.length && (
          <InfoSectionContainer
            colorScheme={colorScheme}
            border={color === 'WHITE'}
          >
            {articleCallout?.icon?.name && (
              <DynamicFeatherIcon
                {...articleCallout.icon}
                className="mr-2"
                size={20}
              />
            )}
            <BlockContent
              blocks={infoSectionText}
              serializers={{
                listItem: ListItem,
                marks: {
                  link: ExternalLink,
                  internalLink: InternalLink,
                  sansSerif: SansSerif,
                },
                types: { block: BlockRenderer },
              }}
            />
          </InfoSectionContainer>
        )}
        <SupportingSectionContainer colorScheme={colorScheme}>
          <BlockContent
            blocks={supportingSectionText}
            serializers={{
              listItem: ListItem,
              marks: {
                link: ExternalLink,
                internalLink: InternalLink,
                sansSerif: SansSerif,
              },
              types: { block: BlockRenderer },
            }}
          />
        </SupportingSectionContainer>
      </TextContainer>
    </Container>
  );
};

export default ArticleCallout;
