import get from 'lodash/get';
import { WithRouterProps } from 'next/dist/client/with-router';
import { RouteMap } from 'constants/Routes';

export const isContentHubOrArticlePage = (
  router: WithRouterProps['router']
) => {
  const contentHubSlugs = get(router, 'query.contentHubSlugs', []);

  return (
    router.pathname === RouteMap.CONTENT_HUB_LANDING.path ||
    contentHubSlugs.length > 0
  );
};
