import { BuoyLocationResponse, GeocodeResponse } from 'types';

export const parseGoogleGeocodeResponse: (
  geocodedData: google.maps.GeocoderResult
) => GeocodeResponse = (geocodedData) => {
  const { address_components: addressData, geometry } = geocodedData;
  return {
    admin1: addressData.find((d) =>
      d.types.includes('administrative_area_level_1')
    )?.long_name,
    admin1_code: addressData.find((d) =>
      d.types.includes('administrative_area_level_1')
    )?.short_name,
    city: addressData.find((d) => d.types.includes('locality'))?.long_name,
    country: addressData.find((d) => d.types.includes('country'))?.long_name,
    country_code: addressData.find((d) => d.types.includes('country'))
      ?.short_name,
    latitude: geometry.location.lat(),
    longitude: geometry.location.lng(),
    postal: addressData.find((d) => d.types.includes('postal_code'))
      ?.short_name,
  };
};

export const parseBuoyLocationResponse: (
  locationResponse: BuoyLocationResponse
) => GeocodeResponse = (locationData) => {
  return {
    admin1: locationData['country-region-name'],
    admin1_code: locationData['country-region'],
    city: locationData.city,
    country: locationData['country-name'],
    country_code: locationData['country'],
    latitude: parseFloat(locationData.latitude),
    longitude: parseFloat(locationData.longitude),
    postal: locationData['postal-code'],
  };
};

export const getCookieName = (rawData: Partial<GeocodeResponse>) =>
  `USER-LOCATION-${JSON.stringify(rawData)}`;
