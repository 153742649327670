import React from 'react';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import useAdPlacements, { AD_TYPES } from 'hooks/useAdPlacements';

const MobileNavDrawer = ({ children, ...rest }: DrawerProps) => {
  const ads = useAdPlacements();
  const bottomRailAdHeight = ads[AD_TYPES.BOTTOM_RAIL]?.clientHeight || 0;

  return (
    <Drawer
      sx={{
        width: '100vw',
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: '100vw',
          boxSizing: 'border-box',
          paddingTop: '16px',
          paddingBottom: `${bottomRailAdHeight + 16}px`,
          overflowX: 'hidden',
        },
      }}
      {...rest}
    >
      {/* This utility adds padding based on navbar height */}
      <Toolbar />
      {children}
    </Drawer>
  );
};

export default MobileNavDrawer;
