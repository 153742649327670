import theme from '@buoyhealth/common.buoy-theme';

const { palette } = theme;

enum Breakpoint {
  xs = '375px',
  sm = '768px',
  md = '1024px',
  lg = '1280px',
  xl = '1440px',
}

export const breakpoints = [
  Breakpoint.xs,
  Breakpoint.sm,
  Breakpoint.md,
  Breakpoint.lg,
  Breakpoint.xl,
];

export const mediaQueries = {
  xs: `@media screen and (min-width: ${Breakpoint.xs})`,
  sm: `@media screen and (min-width: ${Breakpoint.sm})`,
  md: `@media screen and (min-width: ${Breakpoint.md})`,
  lg: `@media screen and (min-width: ${Breakpoint.lg})`,
  xl: `@media screen and (min-width: ${Breakpoint.xl})`,
  'max-xs': `@media screen and (max-width: ${Breakpoint.xs})`,
  'max-sm': `@media screen and (max-width: ${Breakpoint.sm})`,
  'max-md': `@media screen and (max-width: ${Breakpoint.md})`,
  'max-lg': `@media screen and (max-width: ${Breakpoint.lg})`,
  'max-xl': `@media screen and (max-width: ${Breakpoint.xl})`,
};

export const space = {
  0: '0rem',
  0.2: '0.2rem',
  0.25: '0.25rem',
  0.375: '0.375rem',
  0.5: '0.5rem',
  0.625: '0.625rem',
  0.75: '0.75rem',
  1: '1rem',
  1.25: '1.25rem',
  1.5: '1.5rem',
  1.75: '1.75rem',
  2: '2rem',
  2.25: '2.25rem',
  2.5: '2.5rem',
  2.75: '2.75rem',
  3: '3rem',
  3.5: '3.5rem',
  3.75: '3.75rem',
  4: '4rem',
  4.25: '4.25rem',
  4.5: '4.5rem',
  5: '5rem',
  5.25: '5.25rem',
  5.5: '5.5rem',
  6: '6rem',
  8: '8rem',
  9: '9rem',
  12: '12rem',
};

export const sizes = {
  carouselMobilePadding: '1.5rem',
  carouselDesktopPadding: '2.25rem',
  socialShareIconMobile: '1.5rem',
  socialShareIconDesktop: '1.774375rem',
  circledNumber: '3.3125rem',
  topBarHeightMobile: '3.6875rem',
  topBarHeightTablet: '5.25rem',
  footerMenuWidthDesktop: '20vw',
  notificationBarCloseIcon: '0.574375rem',
  articleContentPaddingMobile: '1.5rem',
  articleContentPaddingTablet: '2.25rem',
  fullPageViewButtonHeightMobile: '3.125rem',
  fullPageViewButtonHeightDesktop: '7.0625rem',
  blogPostViewMaxWidth: '46.875rem',
  tabNavigationHeight: '4.5625rem',
  tabNavigationBottomVariantHeight: '6rem',
  drugPageNavWidth: '15.625rem',
  drugPageContentPaddingMobile: '1rem',
  drugPageContentPaddingTablet: '1.5rem',
  breadcrumbBarHeightMobile: '4.5625rem',
  breadcrumbBarHeightDesktop: '5.5rem',
  articleHeroWidthMdDesktop: '55.063125rem',
  articleContentWidthXlDesktop: '46.875rem',
  articleContentWidthTablet: '46rem',
  articleRightPanelWidth: '14.375rem',
  cardThumbnailImageHeight: '7.5rem',
  cardThumbnailImageWidthDesktop: '7.5rem',
  cardTileListMaxWidth: '90rem',
  pauseUxCtaPopUpMaxWidth: '30rem',
  quizContentWidthXlDesktop: '46.875rem',
  quizContentWidthTablet: '46rem',
};

export const borders = {
  gray: `1px solid ${palette.gray[50]}`,
  'gray-70': `1px solid ${palette.gray[70]}`,
  'gray-10': `1px solid ${palette.gray[10]}`,
  'gray-30': `1px solid ${palette.gray[30]}`,
  'blue-darker': `1px solid ${palette.blue[80]}`,
  'black-opacity-20': '1px solid rgba(50, 50, 50, 0.2)',
  white: `1px solid ${palette.common.white}`,
  red: `1px solid ${palette.red[70]}`,
  green: `1px solid ${palette.status.success}`,
  transparent: '1px solid transparent',
  'teal-50': `1px solid ${palette.teal[50]}`,
  'teal-70': `1px solid ${palette.teal[70]}`,
  blue: `1px solid ${palette.primary.main}`,
  'blue-medium': `1px solid ${palette.blue[70]}`,
};

// MAX Z INDEX FTW
const Z_INDEX_MAX = 2147483647;

const zIndices = {
  below: -1,
  '0': 0,
  '1': 1,
  '2': 2,
  '3': 3,
  '4': 4,
  'hero-button': 300,
  'article-cta-pop-up': 550,
  'tab-nav': 600,
  'pop-up': 650,
  nav: 700,
  'progress-bar': 725,
  'notification-bar': 699,
  overlay: 800,
  'above-ads': Z_INDEX_MAX - 4,
  'polaris-backdrop': Z_INDEX_MAX - 3,
  polaris: Z_INDEX_MAX - 2,
  'polaris-overlay': Z_INDEX_MAX - 1,
};

export const zIndex = (name: keyof typeof zIndices) => {
  return {
    zIndex: zIndices[name],
  };
};
