import React, { MouseEvent } from 'react';
import BlockContent from '@sanity/block-content-to-react';
import get from 'lodash/get';

import sanitizeInternalLink from 'state/sanitizers/sanitizeInternalLink';
import sanitizeArticleCallout from 'state/sanitizers/PortableTextModules/sanitizeArticleCallout';
import sanitizeArticleImage from 'state/sanitizers/PortableTextModules/sanitizeArticleImage';
import sanitizeArticleVideo from 'state/sanitizers/PortableTextModules/sanitizeArticleVideo';
import sanitizeImage from 'state/sanitizers/sanitizeImage';

import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';

import Button from 'styled/components/Button';
import Video from 'styled/components/Video';
import Img from 'styled/components/Img';
import {
  PortableTextButton,
  PortableTextLink,
  PortableTextH1,
  PortableTextH2,
  PortableTextH3,
  PortableTextH4,
} from 'styled/components/portableText';

import ArticleCalloutComponent from 'styled/components/portableTextModules/ArticleCallout';
import ArticleImageComponent from 'components/portableTextModules/ArticleImage';
import InlineImageWithCaption from 'styled/components/modules/InterviewModule/InlineImageWithCaption';
import { trackStructuredEvent } from 'analytics';

export const Blue = (props: unknown) => {
  const children = get(props, 'children', null);

  if (!children) {
    return null;
  }

  return <span className="color-blue-50">{children}</span>;
};

export const TextSmall = (props: unknown) => {
  const { style = 'normal' } = get(props, 'node');
  const children = get(props, 'children', null);

  if (!children) {
    return null;
  }

  if (style === 'smallText') {
    return <span className="text-article-sm">{children}</span>;
  }

  /* default to normal behavior */
  return BlockContent.defaultSerializers.types.block(props);
};

export const SansSerif = (props: unknown) => {
  const children = get(props, 'children', null);

  if (!children) {
    return null;
  }

  return <span className="text-sans-serif">{children}</span>;
};

export const ExternalLink = (props: unknown) => {
  const children = get(props, 'children', '');
  const href = get(props, 'mark.href', '');
  const sanitizedInternalLink = sanitizeInternalLink(href);
  const hrefIsRelative = sanitizedInternalLink.charAt(0) === '/';
  const gtmTrackerEventName = get(props, 'mark.gtmTrackerEventName', undefined);
  const snowplowTracking = get(props, 'mark.snowplowTracking', undefined);
  const rel = get(props, 'mark.rel', undefined);
  const variant = get(props, 'mark.variant', undefined);
  const onClick = get(props, 'onClick', undefined);

  if (!children) {
    return null;
  }

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    if (snowplowTracking) {
      trackStructuredEvent(snowplowTracking);
    }
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <PortableTextButton
      as={Button}
      to={sanitizedInternalLink}
      ariaLabel={children}
      openInCurrentTab={hrefIsRelative}
      onClick={handleClick}
      rel={rel}
      variant={variant}
      gtmTrackerEventName={gtmTrackerEventName}
    >
      <span>{children}</span>
    </PortableTextButton>
  );
};

export const InternalLink = (props: any) => {
  const children = get(props, 'children', '');
  const href = get(props, 'mark.link', '');
  const sanitizedInternalLink = sanitizeInternalLink(href);
  const gtmTrackerEventName = get(props, 'mark.gtmTrackerEventName', undefined);
  const snowplowTracking = get(props, 'mark.snowplowTracking', undefined);
  const rel = get(props, 'mark.rel', undefined);
  const variant = get(props, 'mark.variant', undefined);
  const onClick = get(props, 'onClick', undefined);

  if (!children) {
    return null;
  }

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    if (snowplowTracking) {
      trackStructuredEvent(snowplowTracking);
    }

    if (onClick) {
      onClick(event);
    }
  };

  /* Editors can choose button styling for InternalLink, commonly used for the Symptom Checker CTA in ArticleBodyModules. If they select a variant, the Button with that variant is rendered instead of PortableTextLink, to override any Base styling on that link. */
  if (
    variant === 'symptom-checker-white' ||
    variant === 'symptom-checker-blue'
  ) {
    return (
      <Button
        containerClassName="inline-flex"
        to={sanitizedInternalLink}
        ariaLabel={children}
        label={children}
        onClick={handleClick}
        rel={rel}
        openInCurrentTab={true}
        variant={variant}
        gtmTrackerEventName={gtmTrackerEventName}
        polarisTrackingLocation={props.polarisTrackingLocation}
      />
    );
  }

  return (
    <PortableTextLink
      as={Button}
      to={sanitizedInternalLink}
      ariaLabel={children}
      onClick={handleClick}
      rel={rel}
      openInCurrentTab={true}
      gtmTrackerEventName={gtmTrackerEventName}
      polarisTrackingLocation={props.polarisTrackingLocation}
    >
      <span>{children}</span>
    </PortableTextLink>
  );
};

export const TextModuleImage = (props: unknown) => {
  if (!get(props, 'node')) {
    return null;
  }

  const image = sanitizeImage(get(props, 'node.asset'), get(props, 'node.alt'));

  return (
    <Img
      alt={image.alt}
      className="TextModule__image wauto"
      sizes="(max-width: 768px) 400px, (max-width: 1024px) 500px, 650px"
      src={sanityImgUtil(image, 400)}
      srcSet={sanityImgSrcSetUtil(image, 400, 500, 650)}
      dimensions={image.metadata?.dimensions}
      crop={image.crop}
      useOriginalImageSize
    />
  );
};

export const ImageWithCaption = (props: unknown) => {
  if (!get(props, 'node')) {
    return null;
  }

  const image = sanitizeImage(
    get(props, 'node.image.asset'),
    get(props, 'node.image.alt'),
  );

  return (
    <InlineImageWithCaption
      image={image}
      caption={get(props, 'node.caption', '')}
      inlineImageWidth={get(props, 'node.inlineImageWidth', 40)}
    />
  );
};

export const ArticleImage = (props: unknown) => {
  return (
    <ArticleImageComponent
      articleImage={sanitizeArticleImage(get(props, 'node'))}
    />
  );
};

export const ArticleCallout = (props: unknown) => {
  return (
    <ArticleCalloutComponent
      articleCallout={sanitizeArticleCallout(get(props, 'node'))}
    />
  );
};

export const ArticleVideo = (props: unknown) => {
  const sanitizedArticleVideo = sanitizeArticleVideo(get(props, 'node'));

  return (
    <Video
      video={sanitizedArticleVideo}
      isFullWidth={sanitizedArticleVideo?.isFullWidth}
      caption={sanitizedArticleVideo?.caption}
      isInsidePortableText
      showVimeoVideoControl
    />
  );
};

export const ListItem = (props: unknown) => {
  const children = get(props, 'children', null);

  if (!children) {
    return null;
  }

  return (
    <li>
      <span>{children}</span>
    </li>
  );
};

/**
 * Renders portable text without HTML heading tags but with heading styling
 */
export const BlockRenderer = (props: unknown) => {
  const children = get(props, 'children', null);
  const style = get(props, 'node.style', 'normal');

  if (!children) {
    return null;
  }

  if (style === 'h1') {
    return <PortableTextH1>{children}</PortableTextH1>;
  }

  if (style === 'h2') {
    return <PortableTextH2>{children}</PortableTextH2>;
  }

  if (style === 'h3') {
    return <PortableTextH3>{children}</PortableTextH3>;
  }

  if (style === 'h4') {
    return <PortableTextH4>{children}</PortableTextH4>;
  }

  // Fall back to default handling
  return BlockContent.defaultSerializers.types.block(props);
};
