import React from 'react';
import { AnchorLabel } from 'styled/components/base';
import { NavLink } from '../MobileNavBar.style';
import { ArticleItem, ArticleItems } from './MobileArticles.style';

import type { TrendingArticle } from '../../types';
import { ArticleItemImage } from '../../Navigation.style';
import {
  RandomDefaultImage,
  getNavBarLinkLabel,
  getTrendingArticleLink,
} from '../../utils';
import { trackUserClicksTrendingArticle } from '../../analytics';
import { DIAGNOSTIC_TOOLS_LABEL } from './constants';

type Props = {
  articles: TrendingArticle[];
  label: string;
  labelFormat?: string;
  onViewAllClick?: () => void;
  slug: string;
};

function MobileArticles(props: Props) {
  const { articles = [], label, labelFormat, onViewAllClick, slug } = props;

  const navBarLinkLabel = label.includes(DIAGNOSTIC_TOOLS_LABEL)
    ? ''
    : getNavBarLinkLabel(label, { labelFormat });

  return (
    <ArticleItems>
      {articles.map((article, i) => {
        const url = getTrendingArticleLink({
          article,
        });
        return (
          <ArticleItem key={article.slug}>
            <NavLink
              ariaLabel={article.title}
              to={url}
              onClick={() => trackUserClicksTrendingArticle(url)}
            >
              <ArticleItemImage
                color={article.heroColor}
                title={article?.heroImage?.alt}
              >
                {article?.heroImage?.url ? (
                  <img src={`${article?.heroImage.url}?h=200&auto=format`} />
                ) : (
                  <RandomDefaultImage index={i} />
                )}
              </ArticleItemImage>
              <AnchorLabel>{article.title}</AnchorLabel>
            </NavLink>
          </ArticleItem>
        );
      })}

      {slug && navBarLinkLabel && (
        <ArticleItem>
          <NavLink
            ariaLabel={navBarLinkLabel}
            label={navBarLinkLabel}
            onClick={onViewAllClick}
            to={slug}
          />
        </ArticleItem>
      )}
    </ArticleItems>
  );
}

export default MobileArticles;
