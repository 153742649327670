import { useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import { PUB_ID, PW_UNITS, WEBSITE_ID } from './constants';
import useFeatureFlags from 'hooks/useFeatureFlags';
import usePage from 'hooks/usePage';

export type PWUnit = { type: string; selectorId?: string };

export interface RampGlobal {
  que: Array<unknown>;
  addUnits: (units: PWUnit[]) => Promise<unknown>;
  displayUnits: () => void;
  destroyUnits: (units: string[]) => Promise<unknown>;
  settings: {
    slots?: unknown;
  };
  passiveMode: boolean;
  forcePath?: string;
  setPath: (path: string) => void;
}

// Function to add ad units
const addUnits = () => {
  console.log('>>>ADD UNITS:', PW_UNITS);
  window.ramp?.que?.push(() => {
    window.ramp
      ?.addUnits(PW_UNITS)
      .catch((e) => console.warn('>>>ERROR ADDING UNITS:', e))
      .finally(() => {
        console.log('>>>ADD UNITS SUCCESS:', window.ramp?.settings?.slots);
        window.ramp?.displayUnits();
      });
  });
};

// Cleanup function to remove ad units
const removeUnits = () => {
  if (!window.ramp?.settings?.slots) {
    console.warn('>>>NO UNITS FOUND TO REMOVE');
    return;
  }
  const slotsToRemove = Object.keys(window.ramp.settings.slots);
  console.log('>>>REMOVE UNITS:', slotsToRemove);
  window.ramp?.que?.push(() => {
    window.ramp
      ?.destroyUnits?.(slotsToRemove)
      .catch((e) => console.warn('>>>ERROR REMOVING UNITS:', e))
      .then(() => {
        console.log('>>>REMOVE UNITS SUCCESS:', window.ramp?.settings?.slots);
      });
  });
};

// Cleanup function to remove then re-add ad units
const cleanUp = () => {
  if (!window.ramp?.settings?.slots) {
    console.warn('>>>NO UNITS FOUND TO REMOVE');
    return;
  }
  const slotsToRemove = Object.keys(window.ramp.settings.slots);
  console.log('>>>REMOVE UNITS:', slotsToRemove);
  window.ramp?.que?.push(() => {
    window.ramp
      ?.destroyUnits?.(slotsToRemove)
      .catch((e) => console.warn('>>>ERROR REMOVING UNITS:', e))
      .then(() => {
        console.log('>>>REMOVE UNITS SUCCESS:', window.ramp?.settings?.slots);
        addUnits();
      });
  });
};

/** Component to show Playwire Ads */
const Ramp = () => {
  const router = useRouter();
  const featureFlags = useFeatureFlags();
  const pageContext = usePage();

  const pathName = useMemo(
    () => router.asPath.split('#')?.[0],
    [router.asPath],
  );

  const hideAds = useMemo(() => {
    // Disable ads when running Cypress tests
    if (typeof window !== 'undefined' && window.Cypress) {
      return true;
    }

    const disabled =
      pageContext.article?.disableAds ||
      pageContext.blogPost?.disableAds ||
      pageContext.drugPage?.disableAds ||
      pageContext.frontPage?.disableAds ||
      pageContext.page?.disableAds;

    return disabled || !featureFlags['growth-public-site-playwire-enabled'];
  }, [featureFlags, pageContext]);

  useEffect(() => {
    if (hideAds) {
      return;
    }

    if (!window.ramp) {
      // @ts-ignore
      window.ramp = window.ramp || {};

      if (window.ramp) {
        window.ramp.que = window.ramp?.que || [];
        window.ramp.passiveMode = true;
      }

      // Load the Ramp configuration script
      const configScript = document.createElement('script');
      configScript.src = `https://cdn.intergient.com/${PUB_ID}/${WEBSITE_ID}/ramp.js`;
      document.head.appendChild(configScript);
      configScript.onload = addUnits;

      // Connect with google tag manager
      window._pwGA4PageviewId = ''.concat(Date.now().toString());
      window.dataLayer = window.dataLayer || [];
      window.gtag =
        window.gtag ||
        function (...args: any) {
          window.dataLayer.push(args);
        };
      window.gtag('js', new Date());
      window.gtag('config', 'G-G6XGKCEGXB', { send_page_view: false });
      window.gtag('event', 'ramp_js', {
        send_to: 'G-G6XGKCEGXB',
        pageview_id: window._pwGA4PageviewId,
      });
    }

    // Cleanup function to remove units on component unmount
    return () => {
      cleanUp();
    };
  }, [pathName, hideAds]);

  useEffect(() => {
    if (hideAds) {
      removeUnits();
    }
  }, [hideAds, pathName]);

  return null;
};
export default Ramp;
