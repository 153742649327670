import { DefaultSeoSettings } from 'constants/Default';
import { GeneralModuleColor } from 'constants/ColorScheme';

import {
  Action,
  ErrorPage,
  Footer,
  GeneralInquiry,
  Image,
  InputField,
  NavList,
  NotificationBar,
  PopUp,
  PortableText,
  SeoSettings,
  SurveyPage,
  VerifiedByExpertsPopUp,
} from 'types';

export interface GlobalSettingsReducer {
  styleGuideLink: string;
  navigation: NavList;
  notificationBar: NotificationBar;
  footer: Footer;
  popUp: PopUp;
  verifiedByExpertsPopUp: VerifiedByExpertsPopUp;
  errorPage: ErrorPage;
  articleShareUserStory: {
    inputFields: InputField[];
    description: string;
    disclaimer: string;
  };
  generalInquiry: GeneralInquiry;
  seo: SeoSettings;
  instagramToken: string;
  research: {
    surveyPages: SurveyPage[];
    surveyUrl: string;
    surveyQuestionCount: number;
    surveyCustomVariables: {
      [name: string]: string;
    };
    moduleTitle: string;
    moduleDescription: string;
    moduleImage: Image | null;
    moduleColor: GeneralModuleColor;
    moduleQuestionCount: number;
    fullPageViewTitle: string;
    fullPageViewDescription: PortableText;
  };
}

const initialState: GlobalSettingsReducer = {
  styleGuideLink: '',
  navigation: {},
  notificationBar: {
    isActive: false,
    text: [],
  },
  footer: {
    social: [],
    menu: [],
    termsSlug: '',
    privacySlug: '',
    cookiesSlug: '',
    footerImage: null,
  },
  popUp: {
    isActive: false,
    text: [],
    continueButtonLabel: '',
    secondaryButtonLabel: '',
    secondaryButtonLink: '',
  },
  verifiedByExpertsPopUp: {
    subheading: '',
    heading: '',
    headingImage: {
      id: '',
      src: '',
      alt: '',
      crop: null,
      hotspot: null,
      metadata: null,
    },
    headingBackgroundColor: 'ROSE',
    text: [],
  },
  errorPage: {
    title: '',
  },
  articleShareUserStory: {
    inputFields: [],
    description: '',
    disclaimer: '',
  },
  generalInquiry: {
    inputFields: [],
    sectionTitle: '',
    sectionDescription: [],
    formDescription: '',
  },
  seo: {
    title: DefaultSeoSettings.title,
    description: DefaultSeoSettings.description,
    image: DefaultSeoSettings.image,
    structuredData: DefaultSeoSettings.structuredData,
    canonicalUrl: DefaultSeoSettings.canonicalUrl,
  },
  instagramToken: '',
  research: {
    surveyPages: [],
    surveyUrl: '',
    surveyQuestionCount: 0,
    surveyCustomVariables: {},
    moduleTitle: '',
    moduleDescription: '',
    moduleImage: null,
    moduleColor: 'ROSE',
    moduleQuestionCount: 1,
    fullPageViewTitle: '',
    fullPageViewDescription: [],
  },
};

function globalSettings(
  state: GlobalSettingsReducer = initialState,
  action: Action
): GlobalSettingsReducer {
  switch (action.type) {
    case 'FETCH_GLOBAL_SETTINGS_FULFILLED':
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export default globalSettings;
