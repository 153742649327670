import React, { useContext } from 'react';
import { AnchorLabel } from 'styled/components/base';
import { NavigationContext } from '../contexts';
import { NavBarLink, Text } from '../DesktopNavBar.style';
import { MenuItem } from '../DesktopNavLinks/DesktopNavLinks.style';
import { ArticleItem, ArticleItems } from './DesktopArticles.style';
import { ArticleItemImage } from '../../Navigation.style';
import { RandomDefaultImage, getTrendingArticleLink } from '../../utils';
import { trackUserClicksTrendingArticle } from '../../analytics';

interface IDesktopArticleProps {
  renderPlaceholderColumn?: boolean;
}

function DesktopArticles({ renderPlaceholderColumn }: IDesktopArticleProps) {
  const { trending } = useContext(NavigationContext);

  const { articles, label } = trending;

  if (!articles.length && !renderPlaceholderColumn) {
    return null;
  }

  return (
    <ArticleItems>
      {Boolean(articles.length) && (
        <MenuItem>
          <Text>Trending in {label.toLowerCase()}</Text>
        </MenuItem>
      )}
      {articles.map((article, i) => {
        const url = getTrendingArticleLink({
          article,
        });
        return (
          <ArticleItem key={article.slug}>
            <NavBarLink
              ariaLabel={article.title}
              to={url}
              onClick={() => trackUserClicksTrendingArticle(url)}
            >
              <ArticleItemImage
                color={article.heroColor}
                title={article?.heroImage?.alt}
              >
                {article?.heroImage?.url ? (
                  <img src={`${article?.heroImage.url}?h=200&auto=format`} />
                ) : (
                  <RandomDefaultImage index={i} />
                )}
              </ArticleItemImage>
              <AnchorLabel>{article.title}</AnchorLabel>
            </NavBarLink>
          </ArticleItem>
        );
      })}
    </ArticleItems>
  );
}

export default DesktopArticles;
