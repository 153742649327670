import React, { useCallback } from 'react';
import qs from 'query-string';
import { trackPopupOpened } from 'analytics/polaris';
import { RouteMap } from 'constants/Routes';
import usePageSlug from 'hooks/usePageSlug';
import usePolarisPopUpContext from 'hooks/usePolarisPopUpContext';

import type { Props as LinkProps } from '../Link';
import type { Props as ButtonProps } from '../Button';
import type {
  EventType,
  MiniInterviewType,
} from '@buoy-components/polaris/types';

interface IButtonProps extends ButtonProps {
  href?: never;
}

type BaseProps = IButtonProps | LinkProps;

/**
 * These interview config keys follows the format:
 * `{slug}-{parent interview type}-{child interview type}`
 *
 * Note, only sxTreatmentGuidance has valid child interviews currently. The redunant strings in the
 * interview config keys is a byproduct of need to support sxTreatmentGuidance. These keys would
 * not change unless a interview type is added/updated.
 */
const INTERVIEW_CONFIG_KEYS: Record<MiniInterviewType, string> = {
  dxoc: 'dxoc-dxoc',
  emergency: 'emergency-emergency',
  pxoc: 'pxoc-pxoc',
  sxTreatmentGuidance: 'sxTreatmentGuidance-treatment',
  symptoms: 'symptoms-symptoms',
  treatment: 'treatment-treatment',
  treatmentGuidance: 'treatmentGuidance-treatmentGuidance',
  txoc: 'txoc-txoc',
};

/**
 * This event corresponds to a listener in the Polaris app.
 */
const INTERVIEW_EVENT_ACTIONS: Record<string, EventType> = {
  open: 'OPEN_INTERVIEW',
};

/**
 * Higher-order component to intercept any links to buoy-assistant.
 * When a component is wrapped with `withPolarisLinkInterceptor`,
 * Polaris will open when the link/button is clicked instead of sending the
 * user to BA.
 *
 * Note: Polaris must be enabled on the page in question.
 */
const withPolarisLinkInterceptor = <P extends BaseProps>(
  Component: React.FC<P>,
) =>
  function WithPolarisLinkInterceptor({
    href: hrefProp,
    onClick,
    polarisTrackingLocation,
    to: toProp,
    ...rest
  }: P) {
    const url = hrefProp || toProp;

    const slug = usePageSlug();

    const {
      eventEmitter,
      isPolarisDisabled,
      setActiveInterview,
      setIsPopupOpen,
    } = usePolarisPopUpContext();

    const isPolarisUrl =
      url?.includes(RouteMap.SYMPTOM_CHECKER.path) ||
      url?.includes('polaris://');

    const shouldLinkOpenPolaris = !isPolarisDisabled && isPolarisUrl;

    const sanitizedUrl = shouldLinkOpenPolaris ? undefined : url;

    /**
     * Handles click events on Polaris actions. It parses the URL of the action, and if the protocol
     * is 'polaris:', it triggers an event with the corresponding action and type.
     */
    const handlePolarisActionClick = useCallback(() => {
      try {
        const { protocol, search } = new URL(url || '');

        const {
          action,
          type,
        }: {
          action?: keyof typeof INTERVIEW_EVENT_ACTIONS;
          type?: MiniInterviewType;
        } = qs.parse(search);

        if (protocol === 'polaris:' && action && type) {
          setActiveInterview(type);

          eventEmitter?.emit(INTERVIEW_EVENT_ACTIONS[action], {
            id: `${slug}-${INTERVIEW_CONFIG_KEYS[type]}`,
          });
        }
      } catch (error) {
        console.debug(error);
      }
    }, [eventEmitter, setActiveInterview, slug, url]);

    const handleOnClick = useCallback(
      (e: React.MouseEvent<any>) => {
        if (shouldLinkOpenPolaris) {
          handlePolarisActionClick();

          setIsPopupOpen(true);
          trackPopupOpened(
            polarisTrackingLocation || Component.name || 'unknown',
          );
        }

        onClick?.(e);
      },
      [
        handlePolarisActionClick,
        onClick,
        polarisTrackingLocation,
        setIsPopupOpen,
        shouldLinkOpenPolaris,
      ],
    );

    const props = {
      ...rest,
      href: hrefProp ? sanitizedUrl : undefined,
      onClick: handleOnClick,
      to: toProp ? sanitizedUrl : undefined,
    } as P;

    return <Component {...props} />;
  };

export default withPolarisLinkInterceptor;
