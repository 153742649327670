import theme from 'styled/theme';
import styled, { css } from 'styled-components';
import Link from 'styled/components/Link';
import { AnchorLabel } from 'styled/components/base';

export const NavBarLink = styled(Link).attrs({
  variant: 'nav-menu',
  bypassNextLink: true,
})<{
  $underlineOnHover?: boolean;
}>`
  font-weight: ${theme.typography.weight.regular};
  padding: 0.75rem 1.5rem;

  ${theme.mediaQueries.md} {
    ${theme.typography.variants.primary.sm}
    padding: 1.25rem 1rem;
  }

  ${({ $underlineOnHover }) =>
    !$underlineOnHover &&
    css`
      ${AnchorLabel} {
        background-image: none !important;
      }
    `}
`;

export const Text = styled.div`
  ${({ theme }) => theme.typography.variants.primary['xs-sm']};
  color: ${({ theme }) => theme.palette.text.primary};
  padding: 1rem;
`;
