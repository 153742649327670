import memoize from 'lodash/memoize';

import get from 'lodash/get';

import { ArticleVideo } from 'types';

export default memoize((articleVideo: unknown): ArticleVideo | null => {
  const id = get(articleVideo, '_key');
  const vimeoId = get(articleVideo, 'vimeoId', '');
  const youtubeId = get(articleVideo, 'youtubeId', '');

  const videoID = vimeoId || youtubeId;

  if (!id || !videoID) {
    return null;
  }

  return {
    id,
    type: get(articleVideo, '_type', 'articleVideo'),
    vimeoId,
    youtubeId,
    isFullWidth: get(articleVideo, 'displayFullWidth', false),
    caption: get(articleVideo, 'caption', ''),
  };
});
