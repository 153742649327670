import imageUrlBuilder from '@sanity/image-url';

import { Image } from 'types';
import { SANITY_DATASET } from 'constants/EnvVars';

const imageBuilder = (
  image: Image,
  width: number,
  quality: number,
  dpr: number,
): string => {
  if (!process.env.sanityProjectId) {
    console.error('Sanity project id is missing');

    return '';
  }

  const builder = imageUrlBuilder({
    projectId: process.env.sanityProjectId,
    dataset: SANITY_DATASET,
  });

  const imageAsImageObject = {
    asset: {
      _ref: image.id,
    },
    crop: image.crop,
    hotspot: image.hotspot,
  };

  return (
    builder
      .image(imageAsImageObject)
      .width(width)
      .quality(quality)
      .dpr(dpr)
      .auto('format')
      .url() || ''
  );
};

export default imageBuilder;
