import memoize from 'lodash/memoize';
import get from 'lodash/get';

import sanitizeImage from 'state/sanitizers/sanitizeImage';
import sanitizeIcon from '../modules/sanitizeIcon';

import { CalloutColorMap } from 'constants/ColorScheme';

import type { ArticleCallout } from 'types';

export default memoize((articleCallout: any): ArticleCallout | null => {
  const id = get(articleCallout, '_key');

  if (!id) {
    return null;
  }

  return {
    id,
    type: get(articleCallout, '_type', 'articleCallout'),
    color: CalloutColorMap[get(articleCallout, 'color', '#80aeff')] || 'BLUE',
    icon: sanitizeIcon(articleCallout),
    infoSectionText: get(articleCallout, 'infoSectionText', []),
    supportingSectionText: get(articleCallout, 'supportingSectionText', []),
    image: sanitizeImage(
      get(articleCallout, 'supportingSectionImage.asset'),
      get(articleCallout, 'supportingSectionImage.alt'),
    ),
  };
});
