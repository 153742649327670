export const PUB_ID = '1025298';
export const WEBSITE_ID = '75011';

/** Types of ads to be enabled */
export const PW_UNITS = [
  { type: 'bottom_rail' },
  { type: 'flex_leaderboard' },
  { type: 'left_rail' },
  { type: 'right_rail' },
  { type: 'corner_ad_video' },
  { type: 'sticky_sidebar' },
  { type: 'sidebar' },
  { type: 'in_content' },
];
